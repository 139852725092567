<template>
  <div class="blacklist">
    <template v-if="tableData.length">
      <div class="blacklist__table">
        <div class="tableItem blacklist__table-choiceAll">
          <el-checkbox
            v-model="checkAll"
            class="tableItem__checkBox"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            <span class="tableItem__content-title data__title">
              Выбрать все
            </span>
          </el-checkbox>

          <div
            v-if="checkedBlacklists.length > 1"
            class="tableItem__removeItems"
            @click="handleTableMenu('controlTableRemoveSelectedItems')"
          >
            Удалить выбранные
          </div>
        </div>

        <el-checkbox-group
          v-model="checkedBlacklists"
          @change="handleCheckedChange"
        >
          <el-checkbox
            v-for="(item, index) in tableData"
            :key="index"
            :label="item"
            class="tableItem"
          >
            <div class="tableItem__content">
              <div class="tableItem__content-data">
                <div>
                  <div class="data__date">
                    <div class="data__date-title">Создана:</div>
                    <div class="data__date-value">
                      {{ getFormattedTzDate(item.created_at, dateFormat) }}
                    </div>
                  </div>
                  <div class="data__date">
                    <div class="data__date-title">Изменена:</div>
                    <div class="data__date-value">
                      {{ getFormattedTzDate(item.updated_at, dateFormat) }}
                    </div>
                  </div>
                </div>

                <div v-if="!isViewer && !isSupport" class="data-buttons">
                  <IconPenMobile @onClick="handleEdit(item)" />
                  <IconTrashMobile @onClick="handleDelete(item)" />
                </div>
              </div>

              <div class="truck">
                <div class="truck__title">Номер авто:</div>
                <div class="truck__value">{{ item.plate_truck }}</div>
              </div>

              <div class="reason">{{ item.reason }}</div>

              <div class="properties">
                <div class="properties__item">
                  <div class="properties__item-title">Срок действия:</div>
                  <div class="properties__item-value">
                    {{
                      item.blocked_until
                        ? blacklistDateBrief(item.blocked_until)
                        : 'Бессрочно'
                    }}
                  </div>
                </div>
                <div class="properties__item">
                  <div class="properties__item-title">Добавил:</div>
                  <div class="properties__item-value">
                    {{ item.blocked_by }}
                  </div>
                </div>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </div>

      <div class="containerMobile">
        <BottomToolbarButton
          title="Добавить авто в ЧС"
          @button-clicked="handleTableMenu('controlTableAddItem')"
        />
      </div>
    </template>
    <TableEmpty
      v-if="!tableData.length && !isLoading"
      warning="У вас не добавлено ни одного автомобиля в черный список"
      proposition="Добавить авто в ЧС"
      @add="handleTableMenu('controlTableAddItem')"
    />
  </div>
</template>

<script>
import { DATE_FORMAT_FULL_RU } from '@/constants/date'
import { blacklistDateBrief, getFormattedTzDate } from '@/core'
import BottomToolbarButton from '@/UI/form/buttons/BottomToolbarButton'
import IconPenMobile from '@/UI/icon/IconTrashMobile'
import IconTrashMobile from '@/UI/icon/IconPenMobile'
import TableEmpty from '@/UI/table/table-empty/TableEmpty.vue'

export default {
  name: 'ControlTableBlacklistMobile',
  components: {
    IconTrashMobile,
    IconPenMobile,
    BottomToolbarButton,
    TableEmpty,
  },
  props: {
    tableData: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedBlacklists: [],
      dateFormat: DATE_FORMAT_FULL_RU,
    }
  },
  methods: {
    blacklistDateBrief,
    getFormattedTzDate,
    emitExporters(val) {
      this.$emit('selectionChange', {
        ids: val.map(item => item.id),
        list: val,
      })
    },
    handleCheckAllChange(val) {
      this.checkedBlacklists = val ? this.tableData : []
      this.isIndeterminate = false

      this.emitExporters(this.checkedBlacklists)
    },
    handleCheckedChange(val) {
      let checkedCount = val.length

      this.checkAll = checkedCount === this.tableData.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.tableData.length

      this.emitExporters(val)
    },
    handleTableMenu(command) {
      this.$emit('selectDropdownMenu', command)
    },
    handleEdit(row) {
      this.$emit('editRow', row)
    },
    handleDelete(row) {
      this.$emit('deleteRow', row)
    },
  },
}
</script>

<style lang="sass" scoped>
.blacklist
  &__table
    margin-bottom: 85px
    &-choiceAll
      display: flex
      align-items: center
      justify-content: space-between
      column-gap: 16px
    .tableItem
      display: flex
      column-gap: 4px
      background: #FFFFFF
      padding: 12px 16px
      margin-bottom: 8px
      margin-right: 0
      border: 1px solid $greyBorder
      &__removeItems
        font-weight: 500
        font-size: 14px
        color: $color-red
      &__content
        display: flex
        flex-direction: column
        width: 100%
        &-data
          display: flex
          align-items: center
          justify-content: space-between
          border-bottom: 1px solid #EBEEF5
          padding-bottom: 4px
          margin-bottom: 6px
          .data__date
            display: flex
            margin-bottom: 8px
            font-size: 12px
            &-title
              width: 75px
              color: $grey-font
            &-value
              font-weight: bold
              color: $main-font
          .data-buttons
            display: flex
            align-items: center
            justify-content: space-between
            column-gap: 12px

        .truck
          display: flex
          align-items: baseline
          gap: 10px
          padding: 10px 0

          &__title
            font-size: 14px
            color: $color-gray
          &__value
            font-size: 16px
            font-weight: bold
            color: $main-font
        .reason
          white-space: break-spaces
          padding: 0 0 10px
          color: $color-dark-gray
          border-bottom: 1px solid #EBEEF5

        .properties
          padding: 10px 0
          &__item
            display: flex
            align-items: baseline
            gap: 4px
            &-title
              font-size: 12px
              color: $grey-font
            &-value
              font-size: 14px
              color: $main-font
</style>
